/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import classNames from "classnames";
import React, { PropsWithChildren } from "react";
// import {
//   ClassNameOverrides,
//   CleanedRTF,
//   GenericRichTextNode,
//   getCleanedRTF,
//   getRTFReferences,
// } from "@bond-london/graphcms-rich-text";
import { GA_imageQuote } from "./GA_imageQuote";
import leftIcon from "../images/Left Button.svg";
import rightIcon from "../images/Right Button.svg";
import colleen from "../images/diversity-Colleen.jpg";
import damian from "../images/diversity-Damian.jpg";
import Luizet from "../images/diversity-Luizet.jpg";
import Timi from "../images/diversity-Timi.jpg";
import Araceli from "../images/diversity-Araceli.jpg";
import Alyssa from "../images/Alyssa.jpg";
let constValue = 1;
export interface cardModalINFO {
  id: string;
  title?: string;
  content?: object;
  image?: string;
  link?: string;
}

export const GA_personcarousel: React.FC<PropsWithChildren<cardModalINFO>> = ({
  title,
  content,
}) => {
  const changeSlidedecrement = () => {
    const values = document.getElementById(
      "slidervalue"
    ) as HTMLInputElement | null;
    const selectedvalue = constValue;
    let decrement = selectedvalue;
    if (decrement != "1") {
      decrement = Number(selectedvalue) - 1;
      constValue = decrement;
      // values.value = decrement;
      window.location.href = "#" + decrement;
      window.scrollTo(0, 800); // execute it straight away
      setTimeout(function () {
        window.scrollTo(0, 800); // run it a bit later also for browser compatibility
      }, 0);
      // const destel = document.getElementById("showplaces") as HTMLInputElement;
      // const srcel = document.getElementById(decrement) as HTMLInputElement;
      // destel.innerHTML = " ";
      // destel.appendChild(srcel);
      const currentdots = document.getElementById(
        "dot" + decrement
      ) as HTMLInputElement;
      const prevdotsdots = document.getElementById(
        "dot" + selectedvalue
      ) as HTMLInputElement;
      currentdots.style.backgroundColor = "#9c3179";
      prevdotsdots.style.backgroundColor = "#C4C4C4";
    }
  };
  const changeSlideincrement = () => {
    const values = document.getElementById(
      "slidervalue"
    ) as HTMLInputElement | null;
    const selectedvalue1 = constValue;
    let increment = selectedvalue1;
    if (increment < Object.keys(content).length) {
      increment = Number(selectedvalue1) + 1;
      constValue = increment;
      // values.value = increment;
      window.location.href = "#" + increment;
      window.scrollTo(0, 800); // execute it straight away
      setTimeout(function () {
        window.scrollTo(0, 800); // run it a bit later also for browser compatibility
      }, 0);
      // const destel = document.getElementById("showplaces") as HTMLInputElement;
      // const srcel = document.getElementById(increment) as HTMLInputElement;
      // destel.innerHTML = " ";
      // destel.appendChild(srcel);
      // destel.parentNode.removeChild(element)
      // if (selectedvalue1 == 1) {
      //   destel.removeChild(prevel);
      // }
      // destel.removeChild(prevel);
      const currentdots = document.getElementById(
        "dot" + increment
      ) as HTMLInputElement;
      const prevdotsdots = document.getElementById(
        "dot" + selectedvalue1
      ) as HTMLInputElement;
      currentdots.style.backgroundColor = "#9c3179";
      prevdotsdots.style.backgroundColor = "#C4C4C4";
    }
  };
  const clicktime = (value: undefined) => {
    const currentdots = document.getElementById(
      "dot" + value
    ) as HTMLInputElement;
    const currentdots1 = document.getElementById(
      "dot" + constValue
    ) as HTMLInputElement;
    currentdots.style.backgroundColor = "#9c3179";
    if (constValue != 0 && constValue != value) {
      constValue = value;
      currentdots1.style.backgroundColor = "#C4C4C4";
    }
    window.location.href = "#" + constValue;
    window.scrollTo(0, 800); // execute it straight away
    setTimeout(function () {
      window.scrollTo(0, 800); // run it a bit later also for browser compatibility
    }, 0);
  };

  let leftClassName: string;
  let rightClassName: string;

  if (!title) {
    leftClassName = "";
    rightClassName =
      "col-start-2 col-span-12 lg:col-start-4 lg:col-span-8 text-center mt-20px lg:mt-0 p1";
  } else if (1 == 1) {
    leftClassName =
      "col-start-2 col-span-12 lg:col-start-2 lg:col-span-5 lg:row-span-2";
    rightClassName =
      "col-start-2 col-span-12 lg:col-start-7 lg:col-span-7 mt-20px lg:mt-0 text-right-margin p3";
  } else {
    leftClassName =
      "col-start-2 col-span-12 lg:col-start-2 lg:col-span-5 lg:row-span-2";
    // rightClassName =
    //   "col-start-2 col-span-12 lg:col-start-7 lg:col-span-7 mt-20px lg:mt-0 text-right-margin p3";
  }

  return (
    <section
      data-component="ga_personCarousel"
      className={classNames("py-section page-grid max-width z-0 bg-white-1")}
    >
      {/* <div className="card">
        <img src={logo8} alt="John" />
        <h1>John Doe</h1>
        <p className="title">CEO & Founder, Example</p>
        <p>Harvard University</p>
      </div> */}
      <div className="col-span-12 col-start-2">
        <center>
          <h2 className={classNames(leftClassName, "p1 ga-paragraph-title")}>
            Inclusion and Diversity conversations
          </h2>
        </center>
      </div>
      <div className="col-span-12 col-start-3 md:col-start-7" id="dotbutton">
        <center>
          <ul id="link-container">
            <li>
              <button
                className="prev-button col-start-2 row-start-3 lg:row-start-2 lg:col-start-1 lg:col-span-1 h-full justify-self-start lg:justify-self-center"
                onClick={() => changeSlidedecrement()}
              >
                <img src={leftIcon} alt="Left" />
              </button>
              <input type="hidden" id="slidervalue" value="1" />
            </li>
            {content.map((n) => (
              <li>
                <button
                  className={classNames(
                    "image-link",
                    n.id == 1 ? "ga_dot1" : ""
                  )}
                  id={`dot${n.id}`}
                  onClick={() => clicktime(n.id)}
                ></button>
              </li>
            ))}
            <li>
              <button
                className="next-button col-start-13 row-start-3 lg:row-start-2 lg:col-start-14 lg:col-span-1 h-full justify-self-end lg:justify-self-center"
                onClick={() => changeSlideincrement()}
              >
                <img src={rightIcon} alt="Right" />
              </button>
            </li>
          </ul>
        </center>
      </div>
      <div className="ga-personcarousel-container col-span-12 col-start-2">
        <div className="ga-personcarousel-item" id="1">
          <div className="grid grid-cols-1 md:grid-cols-1 w-full">
            <div className="bg-white-1">
              <GA_imageQuote
                id={""}
                Quote='"People here don’t take themselves too seriously and everyone that I have  worked with is collaborative, open to admitting when a suggestion or guidance may not have been the best path, and always happy to work as a team"'
                name="Alyssa Griffin"
                workPosition="Associate Principal"
                loaction="Australia and New Zealand"
                position="RIGHT"
                className="ga-padding-top-0 ga-padding-left-0"
                image={Alyssa}
                QAPart={[
                  {
                    label:
                      "Q: Tell us about your background before consulting. What did you study at university? ",
                    message:
                      "My undergraduate degree is in math, and my master’s is in data analytics, so it's quite self-explanatory. What we tend to do here at Partners and Performance, and consulting more broadly, is just problem-solving at its core, and that's often rooted in analytics and has some elements of mathematical problem-solving – it's right up my alley! ",
                    className: "col-start-2",
                  },

                  {
                    label:
                      "Q: What do you enjoy most about working at Partners in Performance? ",
                    message:
                      "What has kept me here has been the variety of work that I've gotten to participate in, as well as the people that I've worked with. People here don’t take themselves too seriously and everyone that I've worked with is collaborative, open to admitting when a suggestion or guidance may not have been the best path, and always happy to work as a team.",
                    className: "col-start-2",
                  },
                  {
                    label:
                      "Q: What are the biggest challenges for women in consulting, and how should organisations address these challenges? ",
                    message:
                      "I think a lot of women often find it challenging working in male-dominated environments like in consulting. We need to be mindful of the impact microaggressions and unconscious biases can have on women in the workplace. For example, not using gender-neutral terms or assuming the male that you’re working with is your boss when that is not the case. These challenges may seem small, but over time, it can really drain you and start to feel like you are constantly swimming upstream.",
                    className: "col-start-2",
                  },
                ]}
              />
            </div>
          </div>
          {/* <div className="grid grid-cols-1 md:grid-cols-1 w-full">
            <div className="bg-white-1">
              <GA_imageQuote
                id={""}
                Quote='"Having diversity of people, who have different ways of doing or thinking about things, is critical to coming up with the right solutions and answers to stay ahead."'
                name="Colleen Grady "
                workPosition="Partner"
                loaction="Australia and New Zealand"
                position="RIGHT"
                image={colleen}
                className="ga-padding-top-0 ga-padding-left-0"
                QAPart={[
                  {
                    label:
                      "Q: Why do you think inclusion and diversity are important for organisations? ",
                    message:
                      "I think the world is changing very fast, so companies cannot just ‘do what they have always done’. Technology disruption is impacting every industry, requiring a lot of problem‑solving in change. The key to problem‑solving is having many different options to consider. Every person will have a different perspective, because they’ve grown up with a different background, different family environment, different levels of affluence or educational background, which will help us come up with the right solutions and answers to stay ahead.",
                    className: "col-start-2",
                  },

                  {
                    label:
                      "Q: How can women today support and empower the next generation of female leaders?",
                    message:
                      "One is just turning up and being there; being very visible and accessible so if people want to talk to you, you are available. I think giving good quality feedback is important, which is the way you learn and grow. Offering feedback around style and presentation, not at a technical level, but in how you present yourself. ",
                    className: "col-start-2",
                  },
                  {
                    label:
                      "Q: What do you think we should all be doing to challenge gender bias and inequality?",
                    message:
                      "Try to challenge stereotypes and generalisations. Individuals within each ‘group’. It can be simple things like challenging language and how people use it – for example if they talk about ‘the sales guy’ or ‘the next CEO, he will be great’. I think it’s just raising those topics and trying to challenge inequality when you see it.",
                    className: "col-start-2",
                  },
                  {
                    label:
                      "Q: What would you like to see change for women in the consulting industry?",
                    message:
                      "Many changes I would like for women, I would like for everybody. I would love to see more focus on efficiency and effectiveness during a reasonable number of hours, rather than thinking working more hours is how to display your value. Enabling people to have balance in their personal lives and the ability to interact with their families and their children. We need to keep challenging ourselves on how we support people in different ways to enable them to take on new challenges.",
                    className: "col-start-2",
                  },
                ]}
              />
            </div>
          </div> */}
        </div>
        <div className="ga-personcarousel-item" id="2">
          <div className="grid grid-cols-1 md:grid-cols-1 w-full">
            <div className="bg-white-1">
              <GA_imageQuote
                id={""}
                Quote='"Our culture is not just something that looks good in a textbook. Every person in the firm lives and breathes it. From the diversity, inclusion, safety, bringing your whole self to work and the overall openness of the culture, we put our money where our mouths are."'
                name="Damian Lahoud​ "
                workPosition="Manager"
                loaction=" Africa"
                position="RIGHT"
                image={damian}
                className="ga-padding-top-0 ga-padding-left-0"
                QAPart={[
                  {
                    label:
                      "Q: What would you say has been one of your most meaningful experiences at Partners in Performance so far?​ ",
                    message:
                      "The interactions with clients. I cannot really pinpoint one client because I’ve been on such a vast variety of different engagements. However, it is great to be able to sit with a client where at the beginning of the engagement you can gauge their hesitancy, but by the end, you can see that you have really brought value to their business. It is nice to be able to experience that joy with them when you bring improvements to their organisations and to their lives. During my time here I have been exposed to things like procurement, the rapid optimisation of capital projects, wiring implementations – things that widened my skill set and knowledge base. I did not think I would have been exposed to so many different parts of the business in such a short time.",
                    className: "col-start-2",
                  },

                  {
                    label: "Q: What have you learned during your time here?",
                    message:
                      "I have learned a lot about myself. In such a dynamic firm, it is very hard not to get swept up in continuously improving, not just to clients, but also improving yourself and your personal development.",
                    className: "col-start-2",
                  },
                  {
                    label:
                      "Q: What do you think we should all be doing to challenge gender bias and inequality?",
                    message:
                      "Try to challenge stereotypes and generalisations. Individuals within each ‘group’. It can be simple things like challenging language and how people use it – for example if they talk about ‘the sales guy’ or ‘the next CEO, he will be great’. I think it’s just raising those topics and trying to challenge inequality when you see it.",
                    className: "col-start-2",
                  },
                  {
                    label: "Q: What makes Partners in Performance different?​",
                    message: `Our culture is not just something that looks good in a textbook. Every person in the firm lives and breathes it. From the diversity, inclusion, safety, bringing your whole self to work and the overall openness of the culture, we put our money where our mouths are. It is encouraging and exciting to be part of that kind of environment. Here, if someone says: "Oh, that's a great idea,” during a workshop, a week later, it will be implemented. The more you get involved within the firm, the more opportunities begin to open up for you.​`,
                    className: "col-start-2",
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="ga-personcarousel-item" id="3">
          <div className="grid grid-cols-1 md:grid-cols-1 w-full">
            <div className="">
              <GA_imageQuote
                id={""}
                Quote='"Inclusion is forming an environment where those individuals can come together and that environment both acknowledges, accepts and respects all of the differences."'
                name="Araceli Fernandez "
                workPosition="Associate Principal​"
                loaction=" North America​"
                position="RIGHT"
                className="ga-padding-top-0 ga-padding-left-0"
                image={Araceli}
                QAPart={[
                  {
                    label: "Q: What does diversity and inclusion mean to you?",
                    message:
                      "Diversity to me is bringing individuals together that come from a variety of forms. That differences can come from race, gender, religion, age and any other uniqueness that that individual brings to the team. Inclusion is really forming an environment where those individuals can come together and that environment both acknowledges, accepts and respects everyone’s differences.",
                    className: "col-start-2",
                  },

                  {
                    label:
                      "Q: What is the one piece of advice you can give to anyone embarking a management consulting career?",
                    message:
                      "I'm going to shamelessly take a quote that I learned from a female that was giving a presentation at a woman and consulting conference. She said, “Be bold in the sense that ask for what you want. Build the confidence in yourself and ignore all the unhealthy fears that keep you from growing and developing. Focus on the things that you want to get to and just keep moving one step at a time.",
                    className: "col-start-2",
                  },
                  {
                    label:
                      "Q: What do you think we should all be doing to challenge gender bias and inequality?",
                    message:
                      "Try to challenge stereotypes and generalisations. Individuals within each ‘group’. It can be simple things like challenging language and how people use it – for example if they talk about ‘the sales guy’ or ‘the next CEO, he will be great’. I think it’s just raising those topics and trying to challenge inequality when you see it.",
                    className: "col-start-2",
                  },
                  {
                    label:
                      "Q: Describe one of your milestone moments at Partners in Performance, and what has made it so special for you.",
                    message:
                      "One of my biggest milestones occurred a few months before my promotion to Associate Principal: it was the moment when I realise that I was no longer ‘green’. ​When you start consulting, you can be placed in different industries during different services and in different regions. It's a lot of firsts. Now, the moment a client explained their problem to me, I instinctively think of all the possible bottlenecks, and next to that, I would put potential solutions or ways to figure out if that was a problem. I have all of this knowledge that I gained throughout my career with Partners in Performance and can now lead our clients to that journey of success that they are looking for.",
                    className: "col-start-2",
                  },
                ]}
              />
            </div>
          </div>
        </div>
        {/* <div className="ga-personcarousel-item" id="4">
          <div className="grid grid-cols-1 md:grid-cols-1 w-full">
            <div className="">
              <GA_imageQuote
                id={""}
                Quote='"We all need to shake off any form of imposter syndrome, be bold and keep pushing forward."'
                name="Timi Adelaja"
                workPosition="Manager​"
                loaction=" Europe​"
                position="RIGHT"
                image={Timi}
                className="ga-padding-top-0 ga-padding-left-0"
                QAPart={[
                  {
                    label: "Q: Tell us about your career path.",
                    message:
                      "I heard about Partners and Performance before I went to business school. At that time, I was living in South Africa and thinking about my next career move. A friend of mine mentioned to me: “why don't you check out this company?”​ As I researched and learn more about Partners in Performance, I love how the firm focuses on implementing and executing strategies to drive lasting impact for their clients and this is something I resonated with when I was completing my MBA. A few months later, I had the opportunity to join the Australia office – also known as the ‘mothership’ and was a great starting experience to my consulting career. In 2020, I relocated with the firm to help build the Europe practice.",
                    className: "col-start-2",
                  },

                  {
                    label:
                      "Q: What has been your favorite thing about your role at Partners in Performance?",
                    message:
                      "The favourite thing about my role here has been delivering results for clients. I'm not just talking about bottom-line results; I'm talking about actually seeing the change within organisations. I think the thing that people don't really talk enough about, particularly in implementing strategy, is the idea behind changing people's behaviours – it is all about going on that journey with the clients, co-developing solutions with them, getting them to understand the rationale behind some of the ideas we're bringing to the table, implementing them and getting them to adopt these new ways of working.",
                    className: "col-start-2",
                  },
                  {
                    label:
                      "Q: What is the one piece of advice you can give to anyone embarking their career at Partners in Performance?",
                    message:
                      "Ask for help and take on constructive feedback.     At Partners in Performance, you are often surrounded by very diverse, smart, intelligent people with a lot of ambition and a lot of achievements in their lives. So, don't shy away from seeking help and asking questions to help you grow. Everyone here actually is really down-to-earth, willing to coach, willing to answer your questions and bring you up that curve as quickly as you need to get up it. The second thing is, obviously, we will be given feedback. We all have to remember that feedback is for your own good, as long as it s given with a lot of care, candor and compassion. Take it on, understand tangible, actionable ways to improve your own performance in the work that you do and improve the areas of development that have been identified. It's only going to help you advance your career here all that much quicker. ​Our Managing Director, Skipp, has always encouraged everyone at Partners in Performance to never let imposter syndrome hold you back. We all need to shake off any form of imposter syndrome, be bold and keep pushing forward. ",
                    className: "col-start-2",
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="ga-personcarousel-item" id="5">
          <div className="grid grid-cols-1 md:grid-cols-1 w-full">
            <div className="bg-white-1">
              <GA_imageQuote
                id={""}
                Quote='"Inclusion and diversity is about more than the policies and programs that we are putting in place; it is about respecting unique needs and perspectives, as well as the potential of all team members."'
                name="Luizet Ruzow"
                workPosition="Partner​"
                loaction=" Africa​"
                position="RIGHT"
                className="ga-padding-top-0 ga-padding-left-0"
                image={Luizet}
                QAPart={[
                  {
                    label:
                      "Q: How do you feel that women in leadership positions can help guide future generations of female leaders?",
                    message:
                      "It starts with empowering young girls: making sure that we encourage girls that they can pursue STEM subjects (science, technology, engineering and mathematics). We need to shut down negativity and boost the self‑esteem of the next generation so that by the time they enter the workforce, they know exactly where they want to take their career. Being more senior, we have a duty to develop female talent. We need to not just have diverse leadership in the future, but today, and we should actively seek opportunities to mentor young women. At Partners in Performance, we have a great buddy system. I see that, alongside our Woman's Think Tank, working very well for our female consultants because both initiatives are part of our responsibility to set an example for continuous learning.",
                    className: "col-start-2",
                  },

                  {
                    label:
                      "Q: What advice would you give to women who are starting out in their consulting careers?",
                    message:
                      "We need to take ownership of our career path. If we know where we want to go, then we can ask for the right type of support, proactively manage our career and decide our priorities. Let us not focus on external factors but look internally and say: “this is what I need.”",
                    className: "col-start-2",
                  },
                  {
                    label:
                      "Q: What do you think we should all be doing to challenge gender bias and inequality?",
                    message:
                      "Speaking up. Sometimes we see it happen, but we can feel inhibited to object because we might be the only woman in a team, or the most junior. My pledge moving forward will be to always speak up because just one person saying something can help all women in the workplace.",
                    className: "col-start-2",
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="ga-personcarousel-item" id="6">
          <div className="grid grid-cols-1 md:grid-cols-1 w-full">
            <div className="bg-white-1">
              <GA_imageQuote
                id={""}
                Quote='"People here don’t take themselves too seriously and everyone that I have  worked with is collaborative, open to admitting when a suggestion or guidance may not have been the best path, and always happy to work as a team"'
                name="Alyssa Griffin"
                workPosition="Manager"
                loaction="Australia and New Zealand"
                position="RIGHT"
                className="ga-padding-top-0 ga-padding-left-0"
                image={Alyssa}
                QAPart={[
                  {
                    label:
                      "Q: Tell us about your background before consulting. What did you study at university? ",
                    message:
                      "My undergraduate degree is in math, and my master’s is in data analytics, so it's quite self-explanatory. What we tend to do here at Partners and Performance, and consulting more broadly, is just problem-solving at its core, and that's often rooted in analytics and has some elements of mathematical problem-solving – it's right up my alley! ",
                    className: "col-start-2",
                  },

                  {
                    label:
                      "Q: What do you enjoy most about working at Partners in Performance? ",
                    message:
                      "What has kept me here has been the variety of work that I've gotten to participate in, as well as the people that I've worked with. People here don’t take themselves too seriously and everyone that I've worked with is collaborative, open to admitting when a suggestion or guidance may not have been the best path, and always happy to work as a team.",
                    className: "col-start-2",
                  },
                  {
                    label:
                      "Q: What are the biggest challenges for women in consulting, and how should organisations address these challenges? ",
                    message:
                      "I think a lot of women often find it challenging working in male-dominated environments like in consulting. We need to be mindful of the impact microaggressions and unconscious biases can have on women in the workplace. For example, not using gender-neutral terms or assuming the male that you’re working with is your boss when that is not the case. These challenges may seem small, but over time, it can really drain you and start to feel like you are constantly swimming upstream.",
                    className: "col-start-2",
                  },
                ]}
              />
            </div>
          </div>
        </div> */}
        {/* ))} */}
      </div>
      {/* <div className="carousel-container col-span-12 col-start-2">
        {content.map((n) => (
          <div className="carousel-item" id={n.id}>
            <div className="col-span-6 md:col-span-6">
              <h2
                className={classNames(leftClassName, "p1 ga-paragraph-title")}
              >
                {n.title}
              </h2>
              <h3
                className={classNames(
                  "col-start-2 col-span-12 lg:col-start-2 lg:col-span-8 lg:row-span-2 mt-20px lg:mt-0 text-right-margin p3 ga-pragraph-Content"
                )}
              >
                {n.content}
              </h3>
            </div>
            <div className="col-span-6 w-full">
              <img
                src={n.link.carouselImage}
                className="ga-image-quotes-person-image"
              />
            </div>
          </div>
        ))}
      </div>
      <div className="col-span-12 col-start-6">
        <ul id="link-container">
          {content.map((n) => (
            <li>
              <a className="image-link" href={`#${n.id}`}></a>
            </li>
          ))}
        </ul>
      </div> */}
    </section>
  );
};
